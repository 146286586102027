<template>
  <el-container class="h-full">
    <el-container>
      <el-main>
        <div style="height: 50px;">
          <page-header style="padding-left: 0" :title="__('User Profile')" />
        </div>
        <div v-if="five9ManagedCurrentUser">
          <span style="white-space: pre">{{ managedUserError }}</span>
        </div>
        <el-tabs v-else v-model="activeName" style="margin-top: 10px;">
          <el-tab-pane :label="__('Profile Settings')" name="profile">
            <ProfileSetting :active-tab="activeName"> </ProfileSetting>
          </el-tab-pane>
          <el-tab-pane :label="__('Password')" name="password">
            <password :active-tab="activeName"></password>
          </el-tab-pane>
        </el-tabs>
      </el-main>
    </el-container>
    <el-aside class="form-aside relative" :width="`${asideWidth}px`">
      <div class="aside-content">
        <div style="font-size: 14px;padding: 30px 15px;color: #A0A8B5">
          {{ asideText }}
        </div>
      </div>
      <slot name="form"></slot>
    </el-aside>
  </el-container>
</template>
<script>
import { mapActions, mapState } from "vuex";
import ProfileSetting from "./pages/ProfileSetting";
import password from "./pages/password";
import PageHeader from "@/components/PageHeader";
import Five9AdminConsoleManagedUser from "@/mixins/Five9AdminConsoleManagedUser";

export default {
  name: "ProfileManageIndex",
  mixins: [Five9AdminConsoleManagedUser],
  components: {
    PageHeader,
    ProfileSetting,
    password
  },
  props: {
    formAction: {
      default: ""
    },
    asideText: {
      default: ""
    },
    asideButtonText: {
      default: "Create"
    },
    asideWidth: {
      type: Number,
      required: false,
      default: 274
    }
  },
  data() {
    return {
      managedUserError: "",
      activeName: "profile"
    };
  },

  computed: {
    ...mapState("users", {
      contents: state => state.users
    })
  },

  methods: {
    ...mapActions("users", {
      get2FA: "get2FA",
      getPasswordValidationSummary: "getPasswordValidationSummary"
    })
  },
  mounted() {
    this.getPasswordValidationSummary();
    this.managedUserError = [
      __("Your account is managed by the service provider administrator."),
      __("Please contact your administrator for more information.")
    ].join("\n");
  }
};
</script>

<style lang="scss" scoped>
.h-full {
  height: 100%;
}

.aside-content {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
}

.form-aside {
  background: white;
  border-left: #f5f5f8 1px solid;
}
</style>
