<template>
  <div style="margin-top: 10px;">
    <el-form
      ref="contentForm"
      :rules="rules"
      :model="contentForm"
      label-position="top"
      size="large"
      v-loading="isLoading"
    >
      <el-row type="flex">
        <el-col :span="12" :offset="0">
          <el-form-item :label="__('Current password')" prop="current_password">
            <el-input
              v-model="contentForm.current_password"
              type="password"
              autocomplete="off"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row type="flex">
        <el-col :span="12" :offset="0">
          <el-row>
            <el-form-item
              :label="__('New password')"
              prop="new_password"
              required
            >
              <el-input
                v-model="contentForm.new_password"
                type="password"
                autocomplete="off"
                show-password
              ></el-input>
            </el-form-item>
          </el-row>
        </el-col>
        <el-col :span="8" :offset="1">
          <el-row>
            <span style="font-size: small">
              {{ this.passwordPolicySummary }}
            </span>
          </el-row>
        </el-col>
      </el-row>

      <el-row type="flex">
        <el-col :span="12" :offset="0">
          <el-form-item
            :label="__('Confirm password')"
            prop="new_password_confirmation"
          >
            <el-input
              v-model="contentForm.new_password_confirmation"
              type="password"
              autocomplete="off"
              show-password
            ></el-input> </el-form-item
        ></el-col>
      </el-row>

      <el-row type="flex">
        <el-col :span="12" :offset="0">
          <el-form-item>
            <el-button type="primary" @click="submitForm" class="createBtn">{{
              __("Update Password")
            }}</el-button>
          </el-form-item></el-col
        >
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "PasswordComponent",
  props: {
    activeTab: {
      type: String,
      required: false,
      default: ""
    }
  },
  computed: {
    ...mapState("users", {
      isLoading: "isLoading",
      passwordPolicySummary: "passwordPolicySummary"
    })
  },
  data() {
    var newPass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(__("Please input the password")));
      } else {
        if (this.valid_password === false) {
          callback(
            new Error(
              // eslint-disable-next-line
              __("Password must be 8 characters in length. It should also contain one number, one uppercase and one special character.")
            )
          );
        }
        if (this.contentForm.new_password_confirmation !== "") {
          this.$refs.contentForm.validateField("new_password_confirmation");
        }
        callback();
      }
    };
    var confirmPass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(__("Please input the password again")));
      } else if (value !== this.contentForm.new_password) {
        callback(new Error(__("Passwords don't match!")));
      } else {
        callback();
      }
    };
    return {
      password_length: 0,
      contains_eight_characters: false,
      contains_number: false,
      contains_uppercase: false,
      contains_special_character: false,
      valid_password: true,
      contentForm: {
        user_id: this.$auth.user().user_id,
        current_password: "",
        new_password: "",
        new_password_confirmation: ""
      },
      rules: {
        current_password: [
          {
            required: true,
            trigger: "blur",
            message: __("Current password is required")
          }
        ],
        new_password: [
          {
            required: true,
            trigger: "blur",
            validator: newPass
          }
        ],
        new_password_confirmation: [
          {
            required: true,
            trigger: "blur",
            validator: confirmPass
          }
        ]
      }
    };
  },
  methods: {
    ...mapActions("users", {
      updateUserPassword: "updateUserPassword"
    }),
    reInitiateForm() {
      this.contentForm.user_id = this.$auth.user().user_id;
      this.contentForm.current_password = "";
      this.contentForm.new_password = "";
      this.contentForm.new_password_confirmation = "";
    },
    submitForm() {
      this.$refs.contentForm.validate(valid => {
        if (valid) {
          const process = this.updateUserPassword;
          process(this.contentForm)
            .then(data => {
              this.$message({
                type: "success",

                // eslint-disable-next-line
                message: __("Password updated successfully. Login again to continue.")
              });
              if (data.status === 200) {
                this.$auth.logout();
              }
            })
            .catch(err => {
              this.$message({
                type: "error",
                message: err.message
              });
            });
        }
      });
    },
    checkPassword() {
      this.password_length = this.contentForm.new_password.length;
      const format = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

      this.contains_eight_characters = this.password_length >= 8;

      this.contains_number = /\d/.test(this.contentForm.new_password);
      this.contains_uppercase = /[A-Z]/.test(this.contentForm.new_password);
      this.contains_special_character = format.test(
        this.contentForm.new_password
      );

      this.valid_password =
        this.contains_eight_characters === true &&
        this.contains_special_character === true &&
        this.contains_uppercase === true &&
        this.contains_number === true;
    }
  },
  watch: {
    activeTab: {
      immediate: true,
      deep: true,
      handler: async function(val, oldVal) {
        if (oldVal !== val && val === "password") {
          this.reInitiateForm();
        }
      }
    }
  }
};
</script>

<style scoped></style>
