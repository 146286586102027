<template>
  <el-select
    v-model="language"
    :placeholder="__('Select a language')"
    filterable
    style="width: 100%"
    class="userLanguageDropDown"
    default-first-option
    v-loading="isLoading"
    :disabled="disabled"
    :clearable="clearable"
  >
    <el-option
      v-for="lang in allLanguages"
      :key="lang.value"
      :label="lang.label"
      :value="lang.value"
    >
      <span style="float: left">{{ lang.label }}</span>
      <span style="float: right; color: #8492a6; font-size: 13px">
        {{ lang.english_hint }}
      </span>
    </el-option>
  </el-select>
</template>

<script>
import TranslationLanguages from "@/translation/lang";

export default {
  name: "ProfileLanguageDropdown",
  data: function() {
    return {};
  },
  props: {
    value: {
      type: String,
      default: "en"
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    },
    clearable: {
      required: false,
      type: Boolean,
      default: false
    },
    isLoading: {
      required: false,
      type: Boolean,
      default: false
    },
    additionalOptions: {
      required: false,
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    /**
     * Get all supported translation language
     */
    allLanguages() {
      return Object.keys(TranslationLanguages).map(lang => {
        return {
          value: lang,
          label: this.getLangLabel(lang),
          english_hint: TranslationLanguages[lang].name
        };
      });
    },

    /**
     * Getter & setter for the value prop
     */
    language: {
      get: function() {
        return this.value;
      },
      set: function(newValue) {
        this.$emit("input", newValue);
      }
    }
  },

  methods: {
    /**
     * Get the label for a translation language
     * @param {String} lang Language code (i.e. en, nl, de, fr)
     */
    getLangLabel: function(lang) {
      try {
        return TranslationLanguages[lang].transliteration === ""
          ? TranslationLanguages[lang].name
          : TranslationLanguages[lang].transliteration;
      } catch (e) {
        return lang;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.userLanguageDropDown {
  ::v-deep .el-select {
    .el-input.is-focus .el-input__inner {
      border-color: var(--theme-color);
    }

    .el-input__inner:hover {
      border-color: var(--theme-color);
    }

    .el-select-dropdown__item.selected {
      color: var(--theme-color);
    }

    .el-input__inner:focus {
      border-color: var(--theme-color);
    }
  }

  .el-select-group__title {
    color: var(--theme-color) !important;
  }
}
</style>
