<template>
  <div style="margin-top: 10px;">
    <el-form
      ref="contentForm"
      :rules="rules"
      :model="contentForm"
      label-position="top"
      size="large"
      v-loading="isLoading"
    >
      <el-row type="flex">
        <el-col :span="12" :offset="0">
          <el-form-item :label="__('Name')" prop="display_name">
            <el-input v-model="contentForm.display_name"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row type="flex">
        <el-col :span="12" :offset="0">
          <el-form-item :label="__('Email')" prop="email">
            <el-input v-model="contentForm.email"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row type="flex">
        <el-col :span="12" :offset="0">
          <el-form-item label="" prop="display_date_format">
            <display-date-format-dropdown
              v-model="contentForm.display_date_format"
              @display-date-format-changed="displayDateFormatChanged"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row type="flex">
        <el-col :span="12" :offset="0">
          <el-form-item :label="__('Timezone')" prop="default_timezone">
            <timezone-dropdown
              :default_timezone="contentForm.default_timezone"
              @timezone-changed="handleTimezoneChange"
            /> </el-form-item
        ></el-col>
      </el-row>

      <el-row type="flex" v-show="showLanguage">
        <el-col :span="12" :offset="0">
          <el-form-item :label="__('Dashboard Language')" prop="language">
            <profile-language-dropdown
              v-model="languagePreference"
            /> </el-form-item
        ></el-col>
      </el-row>

      <!-- 2FA management -->
      <div v-if="!userRoleHasSystemRoleOrScope" style="margin-bottom: 20px">
        <el-row type="flex">
          <el-col :span="12" :offset="0">
            <el-form-item label="2FA" prop="2fa">
              <el-checkbox
                style="margin-left: 2px"
                v-model="contentForm.twofa_enabled"
                @change="handleChange"
              >
                {{ __("Enable") }}
              </el-checkbox>
            </el-form-item>
          </el-col>
        </el-row>
        <div v-if="setupRequiredFor2FA">
          <el-row type="flex" justify="left">
            <el-card shadow="never">
              <SetupTFA v-model="twofa_verify_code" />
            </el-card>
          </el-row>
        </div>
        <div v-else-if="setupCompletedFor2FA">
          <el-row type="flex">
            <el-col :span="12" :offset="0">
              <span>{{ __("2FA has been setup.") }}</span>
            </el-col>
          </el-row>
        </div>
      </div>

      <el-row type="flex">
        <el-col :span="12" :offset="0">
          <el-form-item>
            <el-button type="primary" @click="submitForm" class="createBtn">{{
              __("Update Profile")
            }}</el-button>
          </el-form-item></el-col
        >
      </el-row>
    </el-form>
  </div>
</template>

<script>
import TimezoneDropdown from "../../../../components/TimezoneDropdown";
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import DisplayDateFormatDropdown from "@/components/DisplayDateFormatDropdown";
import ProfileLanguageDropdown from "@/components/ProfileLanguageDropdown";
import _ from "lodash";
import { EventBus } from "@/EventBus";
import SetupTFA from "@/components/2FA/SetupTFA";

export default {
  name: "ProfileSetting",
  components: {
    TimezoneDropdown,
    DisplayDateFormatDropdown,
    ProfileLanguageDropdown,
    SetupTFA
  },
  props: {
    activeTab: {
      type: String,
      required: false,
      default: ""
    }
  },
  computed: {
    ...mapState("app", {
      selectedAccountId: "selectedAccountId"
    }),
    ...mapState("users", {
      isLoading: "isLoading",
      two_fa: "two_fa"
    }),
    ...mapGetters("users", {
      getUser: "getUser"
    }),
    ...mapGetters("app", {
      userRoleHasSystemRoleOrScope: "userRoleHasSystemRoleOrScope"
    }),

    getQRCode() {
      return this.two_fa.qr_code;
    },

    /**
     *  checks if 2fa is enabled for the authenticated user
     *  @returns {boolean}
     */
    is2FAEnabledForUser() {
      return this.$auth.user().twofa_enabled;
    },

    /**
     *  checks if 2fa setup is required if not enabled yet for the authenticated user
     *  and if a new qr code is generated
     *  @returns {boolean}
     */
    setupRequiredFor2FA() {
      return (
        this.contentForm.twofa_enabled &&
        this.getQRCode.length &&
        !this.is2FAEnabledForUser
      );
    },

    /**
     *  checks if 2fa setup is completed if it's already enabled for the authenticated user
     *
     *  @returns {boolean}
     */
    setupCompletedFor2FA() {
      return (
        this.contentForm.twofa_enabled === true && this.is2FAEnabledForUser
      );
    },

    languagePreference: {
      get: function() {
        return this.contentForm.language;
      },
      set: async function(newVal) {
        this.contentForm.language = newVal;
      }
    },

    languageIsChanged: function() {
      return this.contentForm.language !== this.getUserLang();
    },

    /**
     *  Toggle language preference dropdown visibility using LD feature flag
     */

    twofa_verify_code: {
      get() {
        return this.contentForm.twofa_verify_code;
      },

      set(verifyCode) {
        this.contentForm.twofa_verify_code = verifyCode;
      }
    }
  },
  data() {
    return {
      contentForm: {
        user_id: this.$auth.user().user_id,
        display_name: this.$auth.user().display_name,
        email: this.$auth.user().email,
        default_timezone: this.$auth.user().default_timezone,
        language: this.getUserLang(),
        display_date_format: this.$auth.user().display_date_format,
        twofa_enabled: this.$auth.user().twofa_enabled,
        twofa_verify_code: ""
      },
      showLanguage: false,
      rules: {
        display_name: [
          {
            required: true,
            trigger: "blur",
            message: __("Name is required")
          }
        ],
        email: [
          {
            required: true,
            trigger: "blur",
            message: __("Email is required")
          }
        ],
        default_timezone: [
          {
            required: true,
            trigger: "blur",
            message: __("Timezone is required")
          }
        ],
        language: [
          {
            required: true,
            trigger: "blur",
            message: __("Language preference is required")
          }
        ]
      }
    };
  },
  methods: {
    ...mapActions("users", {
      updateUser: "updateUserProfile",
      get2FA: "get2FA"
    }),
    ...mapMutations("users", {
      reset2FA: "SET_2FA"
    }),
    async showLanguagePreference() {
      this.showLanguage = await this.showFeature(
        this.$getConst("MULTI_LANGUAGE_SUPPORT")
      );
    },
    async handleTimezoneChange(timezone) {
      this.contentForm.default_timezone = timezone;
    },
    reInitiateForm() {
      this.contentForm.user_id = this.$auth.user().user_id;
      this.contentForm.display_name = this.$auth.user().display_name;
      this.contentForm.email = this.$auth.user().email;
      this.contentForm.default_timezone = this.$auth.user().default_timezone;
      this.contentForm.language = this.getUserLang();
      this.contentForm.display_date_format = this.$auth.user().display_date_format;
      this.contentForm.twofa_enabled = this.$auth.user().twofa_enabled;
    },
    handleChange(value) {
      if (value === true && this.$auth.user().twofa_enabled === false) {
        this.get2FA();
      } else {
        this.two_fa.qr_code = "";
        this.reset2FA(this.two_fa);
      }
    },
    submitForm() {
      const langChanged = this.languageIsChanged;

      this.$refs.contentForm.validate(valid => {
        if (valid) {
          let oldEmail = this.$auth.user().email;
          const process = this.updateUser;
          process(this.contentForm)
            .then(async data => {
              if (data.status === 200) {
                if (oldEmail !== this.contentForm.email) {
                  this.$message({
                    type: "success",

                    // eslint-disable-next-line
                    message: __("Email updated successfully. Login again to continue.")
                  });
                  this.$auth.logout();
                } else {
                  if (langChanged) {
                    EventBus.$emit(
                      "userLanguageChanged",
                      this.languagePreference
                    );
                  }

                  await this.$auth.fetch();
                  this.$message({
                    type: "success",
                    message: __("Profile updated successfully.")
                  });
                }
              }
            })
            .catch(err => {
              this.$message({
                type: "error",
                message: err.message
              });
            });
        }
      });
    },
    /**
     * handle display date format changed
     * @param displayDateFormat
     */
    displayDateFormatChanged(displayDateFormat) {
      this.contentForm.display_date_format = displayDateFormat;
    },

    /**
     * Get the language preference of the user
     * @returns {String} Returns the language preference or "en" if no preference is set
     */
    getUserLang() {
      const userLang = _.get(this.$auth.user(), "language", "en");
      return _.isNil(userLang) ? "en" : userLang;
    }
  },
  watch: {
    activeTab: {
      immediate: true,
      deep: true,
      handler: async function(val, oldVal) {
        if (oldVal !== val && val === "profile") {
          this.reInitiateForm();
        }
      }
    },
    selectedAccountId: {
      immediate: true,
      async handler(newVal) {
        if (newVal === "all") {
          this.showLanguage = false;
        } else {
          await this.showLanguagePreference();
        }
      }
    }
  }
};
</script>

<style scoped></style>
